<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li class="nav-item">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'item', 'disabled': loadingTable}" :tabindex="loadingTable ? -1 : ''" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#item" @click="!loadingTable && (tab = 'item')">ITEM</a>
                </li>
                <li class="nav-item" v-if="user_data.idStore == 1">
                    <a class="nav-link fw-bolder" :class="{'active': tab == 'desain', 'disabled': loadingTable}" :tabindex="loadingTable ? -1 : ''" :aria-disabled="loadingTable" data-bs-toggle="tab" href="#desain" @click="!loadingTable && (tab = 'desain')">DESAIN</a>
                </li>
            </ul>
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter align-items-center position-relative fs-6" v-if="tab == 'item'">
                        <div class="filter-item">
                            <span>Tipe</span>
                            <el-select v-model="filter.tipe_produk" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Tipe Produk" clearable style="width: 100px;">
                                <el-option v-for="(o, i) in ['MTO', 'RTW', 'DISPLAY']" :key="i" :value="o" :label="o" />
                            </el-select>
                        </div>     
                        <div class="filter-item">
                            <span>Brand</span>
                            <el-select v-model="filter.brand_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Brand" clearable style="width: 200px;">
                                <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>                
                        <div class="filter-item">
                            <span>SKU</span>
                            <el-input v-model="filter.sku" @change="onFilter('item')" @keyup.enter="onFilter('item')" @clear="onFilter('item')" placeholder="Search SKU" clearable style="width: 150px;" />
                        </div>
                        <div class="filter-item">
                            <span>Artikel</span>
                            <el-input v-model="filter.artikel" @change="onFilter('item')" @keyup.enter="onFilter('item')" @clear="onFilter('item')" placeholder="Search artikel" clearable style="width: 150px;"/>
                        </div>
                        <div class="filter-item">
                            <span>KATEGORI</span>
                            <el-select v-model="filter.raw_category_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="kategori" clearable style="width: 200px;" >
                                <el-option v-for="(o, i) in listRawCategory" :key="i" :value="o.id" :label="o.name" :disabled="o.id == 35"/>
                            </el-select>
                        </div> 
                        <div class="filter-item">
                            <span>Vendor</span>
                            <el-select v-model="filter.vendor_id" @change="onFilter('item')" @clear="onFilter('item')" placeholder="Vendor" clearable style="width: 200px;">
                                <el-option v-for="(o, i) in listVendor" :key="i" :value="o.id" :label="o.nama_vendor" />
                            </el-select>
                        </div>     
                        <div class="filter-item d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('item'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('item'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="filter d-flex align-items-center position-relative fs-6" v-if="tab == 'desain'">
                        <div class="filter-item">
                            <span>DESAIN ID</span>
                            <el-input @change="onFilter('desain')" @clear="onFilter('desain')" @keyup.enter="onFilter('desain')" v-model="filter.desain_id" placeholder="Search Desain ID" clearable />
                        </div>
                        <div class="filter-item">
                            <span>JENIS</span>
                            <el-select v-model="filter.jenis_desain" @change="onFilter('desain')" @clear="onFilter('desain')" placeholder="Pilih JENIS" clearable>
                                <el-option v-for="(o, i) in listJenisDesain" :key="i" :value="o.jenis" :label="o.jenis"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>FOLDER</span>
                            <el-select v-model="filter.folder_id" @change="onFilter('desain')" @clear="onFilter('desain')" placeholder="Pilih Folder" clearable>
                                <el-option v-for="(o, i) in listFolderDesain" :key="i" :value="o.id" :label="o.name">
                                    <span style="float: left">{{ o.name }}</span>
                                    <span style="float: right">({{ o.total }})</span>
                                </el-option>
                            </el-select>
                        </div>  
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('desain'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('desain'), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="row g-2 justify-content-end" data-kt-table-toolbar="base">
                        <div class="col-auto" v-if="tab == 'desain'">
                            <router-link to="/master/print_size" target="blank" class="btn btn-bg-light btn-color-dark btn-active-secondary"><i class="fas fa-external-link-alt me-2"></i>Print Size</router-link>
                        </div>
                        <div class="col-auto"><button class="btn btn-primary" @click="modalClick(tab, 'add', null)">{{ tab == 'item' ? 'Add Raw Item' : 'Add Raw Desain' }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive" v-show="false">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content" id="tab_raw_product">
            <div class="tab-pane fade" :class="tab == 'item' ? 'show active' : ''" id="item" role="tabpanel">
                <div id="form-item" class="table-fixed-header table-responsive sticky">
                    <Datatable 
                        :table-header="tableHeaderItem" 
                        :table-data="tableDataItem"
                        :loading="loadingTable"
                        :rows-per-page="50"
                        :total="totalRecordCountItem"
                        :enable-items-per-page-dropdown="false"
                        empty-table-text="no-data"
                    >
                        <template v-slot:cell-index="{ row: data }">
                            <span class="min-w-50px">{{ data.index+1 }}</span>
                        </template>
                        <template v-slot:cell-gambar="{ row: data }">
                            <div v-if="!data.gambar">
                                <small>Kosong</small>
                            </div>
                            <div v-else>
                                <img class="tbl-image" @click="viewImage(data)" :src="data.tipe_produk == 'DISPLAY' ?  img_url_desain+data.gambar : img_url+data.gambar">
                            </div>
                        </template>
                        <template v-slot:cell-tipe_produk="{ row: data }">
                            {{ data.tipe_produk }}
                        </template>
                        <template v-slot:cell-brand="{ row: data }">
                            {{ data.brand_name || '-' }}
                        </template>
                        <template v-slot:cell-sku="{ row: data }">
                            {{ data.sku }}
                        </template>
                        <template v-slot:cell-artikel="{ row: data }">
                            {{ data.artikel }}
                        </template>
                        <template v-slot:cell-raw_category="{ row: data }">
                            {{ data.raw_category }}
                        </template>
                        <template v-slot:cell-color="{ row: data }">
                            {{ data.color }}
                        </template>
                        <template v-slot:cell-size="{ row: data }">
                            {{ data.size }}
                        </template>
                        <template v-slot:cell-price="{ row: data }">
                            {{ formatIDR(data.price, { prefix: 'Rp ' }) }}
                        </template>
                        <template v-slot:cell-created_at="{ row: data }">
                            {{ data.created_at }}
                        </template>
                        <template v-slot:cell-nama_vendor="{ row: data }">
                            {{ data.nama_vendor }}
                        </template>
                        <template v-slot:cell-action="{ row: data}">
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-secondary btn-sm me-1" title="Edit" @click="modalClick(tab, 'edit', data)" >
                                    <i class="fas fa-edit"></i>
                                </button>
                                <el-popconfirm
                                    confirm-button-text="OK"
                                    cancel-button-text="No"
                                    :icon="InfoFilled"
                                    title="Are you sure to delete this?"
                                    @confirm="onDelete('item', data)"
                                >
                                    <template #reference>
                                        <a href="javascript:void(0)" class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </Datatable>
                </div>
            </div>
            <div class="tab-pane fade" :class="tab == 'desain' ? 'show active' : ''" id="desain" role="tabpanel">
                 <div id="form-desain" class="table-fixed-header">
                    <Datatable 
                        :table-header="tableHeaderDesain" 
                        :table-data="tableDataDesain"
                        :loading="loadingTable"
                        :rows-per-page="50"
                        :total="totalRecordCountDesain"
                        :enable-items-per-page-dropdown="false"
                    >
                        <template v-slot:cell-index="{ row: data }">
                            <span class="min-w-100px">{{ data.index+1 }}</span>
                        </template>
                        <template v-slot:cell-gambar="{ row: data }">
                            <div v-if="!data.gambar">
                                <small>Kosong</small>
                            </div>
                            <div v-else>
                                <img class="tbl-image" @click="viewImage(data)" :src="img_url+data.gambar">
                            </div>
                        </template>
                        <template v-slot:cell-sku="{ row: data }">
                            {{ data.sku }}
                        </template>
                        <template v-slot:cell-folder="{ row: data }">
                            {{ data.folder }}
                        </template>
                        <template v-slot:cell-name="{ row: data }">
                            {{ data.name }}
                        </template>
                        <template v-slot:cell-desain_kategori="{ row: data }">
                            <el-popover
                                placement="top"
                                :width="250"
                                trigger="hover"
                                :content="listDesainKategori.length ? listDesainKategori.map(o => o.name).join([separator = ', ']) : 'Tidak ada'"
                            >
                                <template #reference>
                                    <button href="javascript:void(0)" class="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" @mouseover="desainKategori(data.sku)">Detail Kategori</button>
                                </template>
                            </el-popover>
                        </template>
                        <template v-slot:cell-print_size_name="{ row: data }">
                            {{ data.print_size_name }}({{ data.print_size_detail }})
                        </template>
                        <template v-slot:cell-price="{ row: data }">
                            {{ formatIDR(data.price, { prefix: 'Rp ' }) }}
                        </template>
                        <template v-slot:cell-created_at="{ row: data }">
                            {{ data.created_at }}
                        </template>
                        <template v-slot:cell-action="{ row: data}">
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-secondary btn-sm me-1" title="Edit" @click="modalClick(tab, 'edit', data)" >
                                    <i class="fas fa-edit"></i>
                                </button>
                                <el-popconfirm
                                    confirm-button-text="OK"
                                    cancel-button-text="No"
                                    :icon="InfoFilled"
                                    title="Are you sure to delete this?"
                                    @confirm="onDelete('item', data)"
                                >
                                    <template #reference>
                                        <a href="javascript:void(0)" class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </Datatable>
                </div>
            </div>
            <div class="text-center my-3">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
        
        <el-dialog v-model="modalVisible.item" :title="modalTitle + ' Raw Item'" width="600px" :close-on-click-modal="false">
            <Form
                class="form w-100"
                :validation-schema="tempDataItem"
                @submit="onSubmit('item', modalTitle.toLowerCase())"
            >
                <div class="scroll-y me-n7 pe-7">
                    <input type="hidden" name="id" v-model="tempDataItem.id"/>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Tipe Produk</label>
                        <div class="d-flex form-control border-0 px-0" v-if="user_data.idStore == 1">
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'MTO'" id="mto" v-model="tempDataItem.tipe_produk" checked="checked" :disabled="tempDataItem.release"/>
                                <label for="item" class="form-check-label fs-6 fw-bold">MTO</label>
                            </div>
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'RTW'" id="rtw" v-model="tempDataItem.tipe_produk" :disabled="tempDataItem.release"/>
                                <label for="desain" class="form-check-label fs-6 fw-bold">RTW</label>
                            </div>
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'DISPLAY'" id="campur" v-model="tempDataItem.tipe_produk" :disabled="tempDataItem.release"/>
                                <label for="desain" class="form-check-label fs-6 fw-bold">DISPLAY</label>
                            </div>
                        </div>
                        <div class="d-flex form-control border-0 px-0" v-else>
                            <div class="form-check form-check-custom me-5">
                                <input class="form-check-input" type="radio" name="type" :value="'RTW'" id="rtw" v-model="tempDataItem.tipe_produk" checked="checked" :disabled="tempDataItem.release"/>
                                <label for="desain" class="form-check-label fs-6 fw-bold">RTW</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-responsive mb-7">
                      <div class="fv-row col me-3">
                            <label class="required fs-6 fw-bold mb-2">Brand</label>
                            <Field as="select" class="form-select" v-model="tempDataItem.brand_id" name="brand_id" :disabled="tempDataItem.release">
                                <option v-for="v in listBrand" :key="v" :value="v.id">{{ v.name }}</option>
                            </Field>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="brand_id" />
                                </div>
                            </div>
                        </div>
                        <div class="fv-row col me-3">
                            <label class="required fs-6 fw-bold mb-2">Kategori</label>
                            <Field as="select" class="form-select" v-model="tempDataItem.raw_category_id" name="raw_category_id" @change="generate_kode('artikel')" :disabled="tempDataItem.release">
                                <option v-for="v in listRawCategory" :key="v" :value="v.id" :disabled="v.id == 35">{{ v.name }}</option>
                            </Field>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="raw_category_id" />
                                </div>
                            </div>
                        </div>
                        <div class="fv-row col ms-3">
                            <label class="required fs-6 fw-bold mb-2" >Vendor</label>
                            <Field as="select" class="form-select" v-model="tempDataItem.vendor_id" name="vendor_id" @change="generate_kode('artikel')" :disabled="tempDataItem.release">
                                <option v-for="v in listVendor" :key="v" :value="v.id">{{ v.nama_vendor }}</option>
                            </Field>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="vendor_id" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-responsive mb-7">
                        <div class="fv-row col me-3">
                            <label class="required fs-6 fw-bold mb-2">Warna</label>
                            <Field as="select" class="form-select" data-control="select2" v-model="tempDataItem.color_id" data-placeholder="Pilih Color" name="color_id" @change="generate_kode('artikel')" :disabled="tempDataItem.release">
                                <option v-for="c in listColor" :key="c" :value="c.id">{{ c.name }}</option>
                            </Field>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="color_id" />
                                </div>
                            </div>
                        </div>
                        <div class="fv-row col ms-3">
                            <label class="required fs-6 fw-bold mb-2">Artikel (auto generate)</label>
                            <el-popover
                                placement="top"
                                title="Generate Artikel Otomatis"
                                :width="300"
                                trigger="click"
                                content="Generate artikel secara otomatis aktif apabila kategori, vendor dan warna telah dipilih."
                            >
                                <template #reference>
                                    <a class="fs-8 ms-2" href="javascript:void(0)">Pelajari lebih</a>
                                </template>
                            </el-popover>
                            <div class="d-flex">
                                <div class="col">
                                    <Field type="text" v-model="tempDataItem.artikel" name="artikel" @change="generate_kode('sku'), change_artikel = true" v-slot="{ field }" :disabled="tempDataItem.release">
                                        <input v-bind="field" :disabled="change_artikel" class="form-control" placeholder=""/>
                                    </Field>
                                    <div class="fv-plugins-message-container">
                                        <div class="fv-help-block">
                                            <ErrorMessage name="artikel" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!tempDataItem.release">
                                    <el-popconfirm
                                        v-if="change_artikel == true"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        width="50"
                                        title="Apakah ingin edit artikel manual?"
                                        @confirm="change_artikel = false"
                                        @cancel="change_sku = true"
                                        :teleported="false"
                                    >
                                        <template #reference>
                                            <button @click="(e) => e.preventDefault()" class="btn btn-light text-link ms-2">Edit</button>
                                        </template>
                                    </el-popconfirm>
                                    <button v-else class="btn btn-danger ms-2" @click="change_artikel = true">Disable</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-responsive mb-7">
                        <div class="fv-row col me-3">
                            <label class="required fs-6 fw-bold mb-2">Ukuran</label>
                            <Field as="select" class="form-select" v-model="tempDataItem.size_id" name="size_id" @change="generate_kode('sku')" :disabled="tempDataItem.release">
                                <option v-for="s in listSize" :key="s" :value="s.id">{{ s.name }}</option>
                            </Field>
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="size_id" />
                                </div>
                            </div>
                        </div>
                        <div class="fv-row col ms-3">
                            <label class="required fs-6 fw-bold mb-2">SKU (auto generate)</label>
                            <el-popover
                                placement="top"
                                title="Generate SKU Otomatis"
                                :width="300"
                                trigger="click"
                                content="Generate SKU secara otomatis aktif apabila artikel dan size telah dipilih."
                            >
                                <template #reference>
                                    <a class="fs-8 ms-2" href="javascript:void(0)">Pelajari lebih</a>
                                </template>
                            </el-popover>
                            <div class="d-flex">
                                <div class="col">
                                    <Field type="text" v-model="tempDataItem.sku" name="sku" v-slot="{ field }" @change="change_sku = true" :disabled="tempDataItem.release">
                                        <input v-bind="field" :disabled="change_sku" class="form-control" placeholder="" />
                                    </Field>
                                    <div class="fv-plugins-message-container">
                                        <div class="fv-help-block">
                                            <ErrorMessage name="sku" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!tempDataItem.release">
                                    <el-popconfirm
                                        v-if="change_sku == true"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :width="100"
                                        :icon="InfoFilled"
                                        title="Apakah ingin edit SKU manual?"
                                        @confirm="change_sku = false"
                                        @cancel="change_sku = true"
                                    >
                                        <template #reference>
                                            <button @click="(e) => e.preventDefault()" class="btn btn-light text-link ms-2">Edit</button>
                                        </template>
                                    </el-popconfirm>
                                    <button v-else class="btn btn-danger ms-2" @click="change_sku = true">Disable</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-responsive mb-7">
                        <div class="fv-row col me-3">
                            <label class="required fs-6 fw-bold mb-2">HPP Item</label>
                            <Field type="text" class="form-control" placeholder="" v-model="tempDataItem.price" name="price" />
                            <div class="fv-plugins-message-container">
                                <div class="fv-help-block">
                                    <ErrorMessage name="price" />
                                </div>
                            </div>
                        </div>
                        <div class="fv-row col ms-3 file">
                            <label class="required fs-6 fw-bold mb-2">Gambar</label>
                            <input v-show="!tempDataItem.gambar" @change="onFileChange('item')" type="file" ref="imageItem" id="imageItem" class="form-control"/>
                            <div v-if="tempDataItem.gambar" class="d-flex">
                                <div class="col me-1">
                                    <input class="form-control" :value="tempDataItem.gambar.substring(tempDataItem.gambar.lastIndexOf('/') + 1)">
                                </div>
                                <div class="col ms-1">
                                    <button class="btn btn-light text-link" @click="changeImage('item')">Change Image</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7 file">
                        <div class="file-upload"></div>
                        <div v-show="tempDataItem.gambar" class="preview-image">
                            <img id="preview-image" :src="tempDataItem.gambar"/>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modalVisible.item = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="modalVisible.bank_desain" :title="'Pilih dari bank desain'" :close-on-click-modal="true" fullscreen custom-class="dialogSelectDesain">
            <div class="filter position-relative mb-4 fs-6 fw-bolder text-gray-600" ref="searchRef">
                <div class="filter-item">
                    <span>DESAIN ID</span>
                    <el-input v-model="filter.desain_id" placeholder="Search Desain ID" clearable />
                </div>
                <div class="filter-item">
                    <span>JENIS</span>
                    <el-select v-model="filter.jenis_desain" placeholder="Pilih Folder" clearable>
                        <el-option v-for="(o, i) in listJenisDesain" :key="i" :value="o.jenis" :label="o.jenis"/>
                    </el-select>
                </div>
                <div class="filter-item">
                    <span>FOLDER</span>
                    <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable>
                        <el-option v-for="(o, i) in listFolderDesain" :key="i" :value="o.id" :label="o.name">
                            <span style="float: left">{{ o.name }}</span>
                            <span style="float: right">({{ o.total }})</span>
                        </el-option>
                    </el-select>
                </div>
                <div class="d-flex">
                    <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('bankdesain'), currentPage = 1"><i class="fas fa-search"></i></button>
                    <button class="btn btn-icon btn-light-danger" @click="resetFilter('bankdesain'), currentPage = 1"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive" v-show="false">
                <span>Hasil filter</span>
            </div>
            <div class="item" ref="itemRef" v-if="listBankDesain.length > 0">
                <div v-for="(item, index) in listBankDesain" :key="index" class="item-list" @click="getDesign(item)">
                    <div class="item-image mb-2">
                        <img :src="img_url+item.gambar"/>
                        <span class="ribbon left">{{ item.tipe_desain ? item.tipe_desain : 'TIDAK ADA' }}</span>
                    </div>
                    <div class="item-name mb-2">
                        <span>{{ item.name+' ('+item.id+')' }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <img class="data-not-found" src="@/assets/images/absurd/patrick-not-found.png"/>
            </div>
            <template #footer>
                <div class="text-center">
                    <el-pagination 
                        background layout="prev, pager, next" 
                        :page-count="pagination.total_page"
                        @next-click="handlePageDesainChange" 
                        @prev-click="handlePageDesainChange" 
                        @current-change="handlePageDesainChange" 
                        @update:current-page="pagination.page"
                    />
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="modalVisible.desain" :title="modalTitle+' Raw Desain'" width="600px" :close-on-click-modal="true">
            <Form
                class="form w-100"
                :validation-schema="tempDataDesain"
                @submit="onSubmit('desain', modalTitle.toLowerCase())"
            >
                <input type="hidden" name="id" v-model="tempDataDesain.id"/>
                <div class="scroll-y me-n7 pe-7">
                    <inner-image-zoom :src="img_url+tempDataDesain.gambar"/>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Desain ID</label>
                        <Field v-model="tempDataDesain.sku" type="text" name="sku" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Artikel</label>
                        <Field type="text" class="form-control" placeholder="" v-model="tempDataDesain.artikel" name="artikel" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Nama</label>
                        <Field type="text" class="form-control" placeholder="" v-model="tempDataDesain.name" name="name" v-slot="{ field }">
                            <input v-bind="field" class="form-control" disabled/>
                        </Field>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Tipe Desain</label>
                        <span class="form-control">{{ listDesainKategori.length ? listDesainKategori[0].tipe_desain : 'Tidak ada' }}</span>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Desain Kategori</label>
                        <span class="form-control">{{ listDesainKategori.length ? listDesainKategori.map(o => o.name).join([separator = ', ']) : 'Tidak ada' }}</span>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Ukuran Print</label>
                        <Field as="select" class="form-select" v-model="tempDataDesain.print_size_id" name="print_size_id" @change="listPrintSize.find(o => { if(o.id == tempDataDesain.print_size_id) { tempDataDesain.price = o.price } })">
                            <option v-for="ps in listPrintSize" :key="ps" :value="ps.id" @click="tempDataDesain.price = ps.price">{{ ps.name }}({{ ps.detail }})</option>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="print_size_id" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="fs-6 fw-bold mb-2">HPP Print</label>
                        <input :value="tempDataDesain.price ? formatIDR(tempDataDesain.price, { prefix: 'Rp'}) : 0" class="form-control" disabled/>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modalVisible.desain = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>

    </div>
</template>

<script>
import { App } from "vue";
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { ElLoading, Elpagination } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        Datatable,
        Field,
        Form,
        ErrorMessage, 
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const loadingTable = ref(false);
        const loadingButton = ref(false);

        const user_data = computed(() => {
            return store.getters['currentUser'];
        })

        const tab = ref('item')

        const search = ref(null)
        const filterActive = ref(false)
        const filter = reactive({
            brand_id: null,
            desain_id: null,
            sku: null,
            artikel: null,
            raw_category_id: null,
            folder_id: null,
            jenis_desain: null,
            tipe_produk: null,
            vendor_id: null,
        })
        const itemRef = ref(null)

        const change_artikel = ref(true)
        const change_sku = ref(true)
        
        const submitButton = ref(null);
        
        const tableHeaderItem = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Image",
                key: "gambar",
            },
            {
                name: "Tipe Produk",
                key: "tipe_produk",
            },
            {
                name: "Brand",
                key: "brand",
            },
            {
                name: "SKU",
                key: "sku",
            },
            {
                name: "Artikel",
                key: "artikel",
            },
            {
                name: "Raw Category",
                key: "raw_category",
            },
            {
                name: "Warna",
                key: "color",
            },
            {
                name: "Ukuran",
                key: "size",
            },
            {
                name: "HPP",
                key: "price",
                align: "right"
            },
            {
                name: "Vendor",
                key: "nama_vendor",
            },
            {
                name: "Created At",
                key: "created_at",
                align: "left"
            },
            {
                name: "Action",
                key: "action",
            },
        ]);

        const tableDataItem = reactive([]);
        const totalRecordCountItem = ref(0);
        const tempDataItem = reactive(Yup.object().shape({
            id: '',
            gambar: '',
            brand_id: Yup.string().required().label("Brand"),
            sku: Yup.string().required().label("SKU"),
            artikel: Yup.string().required().label("Artikel"),
            raw_category_id: Yup.string().required().label('Kategori'),
            raw_category: '',
            tipe_produk: '',
            color_id: Yup.string().required().label('Warna'),
            size_id: Yup.string().required().label('Ukuran'),
            price: Yup.string().required().label("Harga"),
            nama_vendor: '',
            vendor_id: Yup.string().required().label('Vendor'),
        }));

        const tableHeaderDesain = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Image",
                key: "gambar",
            },
            {
                name: "Desain ID",
                key: "sku",
            },
            {
                name: "Folder",
                key: "folder",
            },
            {
                name: "Nama",
                key: "name",
            },
            {
                name: "Kategori Desain",
                key: "desain_kategori",
            },
            {
                name: "Ukuran Print",
                key: "print_size_name",
            },
            {
                name: "HPP",
                key: "price",
                align: "right"
            },
            {
                name: "Created At",
                key: "created_at",
                align: "left"
            },
            {
                name: "Action",
                key: "action",
            },
        ]);

        const tableDataDesain = reactive([]);
        const totalRecordCountDesain = ref(0);
        const tempDataDesain = reactive(Yup.object().shape({
            id: '',
            gambar: '',
            sku: '',
            artikel: '',
            name: '',
            desain_kategori: '',
            print_size_id: Yup.string().required().label("Ukuran Print"),
            print_size_name: '',
            print_size_detail: '',
            price: '',
        }));

        const listVendor = reactive([])
        const listRawCategory = reactive([])
        const listBrand = reactive([])
        const listColor = reactive([])
        const listSize = reactive([])
        const listPrintSize = reactive([])
        const listDesainKategori = reactive([])

        const modalVisible = ref({
            item: false,
            desain: false,
            bank_desain: false,
        })
        
        const modalTitle = ref('')
        
        const loadingFull = ({message: message = 'Tunggu', background: background = 'rgba(255,255,255,0.7)', lock: lock = true}) => {
            const loading = ElLoading.service({
                lock: lock,
                text: message,
                background: background,
            })

            return loading
        }

        const auto_kode = ref(true);
        
        const modalClick = async (getTab, type, row) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })

            try {
                type == 'add' ? modalTitle.value = 'Add' : modalTitle.value = 'Edit'

                change_artikel.value = true
                change_sku.value = true

                if(getTab == 'item')
                {
                    if(type == 'add') {
                        auto_kode.value = true;
                        // Reset item
                        tempDataItem.id = ''
                        tempDataItem.brand_id = ''
                        tempDataItem.sku = ''
                        tempDataItem.artikel = ''
                        tempDataItem.tipe_produk = 'MTO'
                        tempDataItem.raw_category = ''
                        tempDataItem.raw_category_id = ''
                        tempDataItem.color_id = ''
                        tempDataItem.size_id = ''
                        tempDataItem.price = ''
                        tempDataItem.nama_vendor = ''
                        tempDataItem.vendor_id = ''
                        tempDataItem.gambar = ''

                        if(imageItem.value) {
                            document.getElementById('imageItem').value = null
                        }

                    } else {
                        auto_kode.value = false;
                        await Object.assign(tempDataItem, row);
                        tempDataItem.gambar =  img_url.value + '/' + row.gambar
                    }
                    
                    //await getRawCategory();
                    await getColor();
                    await getSize();
                    //await getVendor();

                    modalVisible.value.item = true
                } 
                else 
                {
                    if(type == 'edit') {
                        getPrintSize()
                        desainKategori(row.sku)
                        // Assign Input Desain
                        tempDataDesain.id = row.id
                        tempDataDesain.sku = row.sku
                        tempDataDesain.artikel = row.artikel
                        tempDataDesain.name = row.name
                        tempDataDesain.price = row.price
                        tempDataDesain.desain_kategori = row.desain_kategori
                        tempDataDesain.gambar = row.gambar
                        tempDataDesain.print_size_name= row.print_size_name
                        tempDataDesain.print_size_detail = row.print_size_detail
                        tempDataDesain.print_size_id = row.print_size_id

                        modalVisible.value.desain = true
                    }
                    else {
                        /*// Get bank desain
                        listBankDesain.splice(0)
                        await ApiService.setHeader();
                        await ApiService.get("bankdesain?page=1")
                        .then(({ data }) => {
                            totalPage.value = data.total_page
                            Object.assign(listBankDesain, data.list_data)
                        })
                        .catch(({ response }) => {
                            console.log('Error getting bank desain');
                        });
                        
                        modalVisible.value.bank_desain = true
                        currentPage.value = 1
                        
                        folderDesain()
                        */

                        router.push({name: 'create_raw_desain'})
                    }
                } 

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loading.close()
        }

        const getColor = async() => {
            try {
                await ApiService.setHeader();
                await ApiService.get("color")
                .then(({ data }) => {
                    Object.assign(listColor, data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list color');
                });
            } catch(e) {
                console.log(e.message)
            }
        }        
        
        const getSize = async() => {
            try {
                await ApiService.setHeader();
                await ApiService.get("size")
                .then(({ data }) => {
                    Object.assign(listSize, data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list item');
                });
            } catch(e) {
                console.log(e.message)
            }
        }

        const getVendor = async() => {
            try {
                await ApiService.setHeader();
                await ApiService.get("vendor")
                .then(({ data }) => {
                    //console.log(data)
                    Object.assign(listVendor, data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list vendor');
                });
            } catch(e) {
                console.log(e.message)
            }
        }

        const getRawCategory = async () => {
            try {
                listRawCategory.splice(0)
                
                await ApiService.get("category")
                .then(({ data }) => {
                    Object.assign(listRawCategory, data)
                })
                .catch(({ response }) => {
                    console.log('Error getting list raw_category');
                });

            } catch(e) {
                console.log(e.message)
            }
        }

        const getListBrand = async () => {
            try {
                await ApiService.get('brand')
                .then((response) => {
                    Object.assign(listBrand, response.data)
                })
                .catch((error) => {
                    console.log('Error getting list brand');
                })
                
            } catch(e) {
                console.log(e.message)
            }
        }

        const getPrintSize = () => {
            try {
                ApiService.get("print_size")
                .then(({ data }) => {
                    Object.assign(listPrintSize, data)
                })
                .catch(({ response }) => {
                    console.log('Error getting list print size');
                });
            } catch(e) {
                console.error(e)
            }
        }

        const getDesign = (row) => {
            // Assign Input Desain
            tempDataDesain.id = row.id
            tempDataDesain.sku = row.id
            tempDataDesain.artikel = 0
            tempDataDesain.name = row.name
            tempDataDesain.price = ''
            tempDataDesain.gambar = row.gambar
            tempDataDesain.print_size_name = ''
            tempDataDesain.print_size_detail = ''
            tempDataDesain.print_size_id = ''

            listPrintSize.splice(0)
            listDesainKategori.splice(0)

            getPrintSize()
            desainKategori(row.id)

            modalVisible.value.desain = true
        }
        
        const listBankDesain = reactive([])
        const currentPage = ref(1)
        const totalPage = ref(1)
   
        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageDesainChange = async (val) => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Tunggu',
                background: 'rgba(255,255,255,0.7)'
            })

            itemRef.value && (itemRef.value.scrollTop = 0) // Reset scroll position
            listBankDesain.splice(0)

            await ApiService.post("bankdesain?page="+val, { search: search.value })
            .then(({ data }) => {
                totalPage.value = data.total_page
                Object.assign(listBankDesain, data.list_data)
            })
            .catch(({ response }) => {
                console.log('Error getting bank desain');
            });

            loading.close()
        }

        const handlePageChange = async (page) => {
            currentPage.value = page
            console.log('a')
            if(tab.value == 'item') {
                await getRawItem()
            } else {
                await getRawDesain()
            }
        }

        const desainKategori = (id) => {
            listDesainKategori.splice(0)
            ApiService.setHeader();
            ApiService.get("desain_kategori/"+id)
            .then(({ data }) => {
                //console.log(data)
                Object.assign(listDesainKategori, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list desain_kategori');
            });
        }
        
        const listJenisDesain = reactive([])
        const listFolderDesain = reactive([])

        const jenisDesain = () => {
            ApiService.get("jenis_desain")
            .then(({ data }) => {
                //console.log(data)
                Object.assign(listJenisDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list jenis desain');
            });
        }

        const folderDesain = () => {
            listFolderDesain.splice(0)

            let endpoint = modalVisible.value.bank_desain ? 'bankdesain_folder' : 'raw_folder'
            ApiService.get(endpoint)
            .then(({ data }) => {
                Object.assign(listFolderDesain, data)
            })
            .catch(({ response }) => {
                console.log('Error getting list folder desain');
            });
        }

        const onFilter = async (type) => {
            
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                return
            }

            store.dispatch(Actions.ADD_FILTER, filter);

            filterActive.value = false
            let data = {}

            if(type == 'bankdesain') {
                const loading = ElLoading.service({
                    lock: true,
                    text: 'Tunggu',
                    background: 'rgba(255,255,255,0.7)'
                })

                await getBankdesain()

                currentPage.value = 1

                loading.close()

            } else {
                loadingTable.value = true;

               if(type == 'item') {
                    await getRawItem()
               } else {
                    await getRawDesain()
               }
                loadingTable.value = false
            }
            
            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }

          Object.keys(filter).map(key => filter[key] = null)

          filterActive.value = false

          if(type == 'bankdesain') {
              const loading = ElLoading.service({
                  lock: true,
                  text: 'Tunggu',
                  background: 'rgba(255,255,255,0.7)'
              })

              await getBankdesain()

              loading.close()
          }
          if(type == 'item') {
              await getRawItem()
          }
          if(type == 'desain') {
              await getRawDesain()
          }

        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = tab.value == "item" ? 'SKU - '+data.sku : 'DESAIN ID - '+data.sku
            viewImageVisible.value = true
            viewImageSrc.value = data.tipe_produk == 'DISPLAY' ? img_url_desain.value+data.gambar : img_url.value+data.gambar;
        }

        // UPLOAD SINGLE FILE
        const [imageItem, imageDesain] = [ref(null), ref(null)]
        const reader = new FileReader();
        const totalSize = ref(0);
        const currentProgress = ref(0);
        function handleEvent(event) {
            if (['loadend', 'load'].includes(event.type)) {
                currentProgress.value = 'Completed 100%';
            }
            if (event.type === 'progress') {
                currentProgress.value = `${(event.loaded / totalSize.value).toFixed(2) * 100}%`;
                //console.log('Loaded: '+event.loaded)
                //console.log('Progress: ', currentProgress.value);
                //console.log('Bytes transferred: ', event.loaded, 'bytes');
            }
            if (event.type === 'loadstart') {
                totalSize.value = event.total;
            }
        }
        function onFileChange(type) {
            var output = document.getElementById('preview-image');

            if(type == 'item') {
                const fileItem = imageItem.value.files[0];
                tempDataItem.gambar = URL.createObjectURL(fileItem);
            } else {
                const fileDesain = imageDesain.value.files[0];
                tempDataDesain.gambar = URL.createObjectURL(fileDesain);
            }

            output.onload = function() {
                //URL.revokeObjectURL(output.src) // free memory
            }
        }
        function changeImage(type) {
            tempDataItem.gambar = ''
            tempDataDesain.gambar = ''

            if(type == "item") {
                setTimeout(() => { 
                    document.getElementById('imageItem').value = null
                    document.getElementById('imageItem').click()
                }, 10);
            }

            if(type == "desain") {
                setTimeout(() => { 
                    document.getElementById('imageDesain').value = null
                    document.getElementById('imageDesain').click()
                }, 10);
            }

        }
        function addListeners(reader) {
            reader.addEventListener('loadstart', handleEvent); 
            reader.addEventListener('load', handleEvent);
            reader.addEventListener('loadend', handleEvent);
            reader.addEventListener('progress', handleEvent);
            reader.addEventListener('error', handleEvent);
            reader.addEventListener('abort', handleEvent);
        }

        const onSubmit = async (getTab, type) => {
            const formData = new FormData();
            let id = ''

            if(submitButton.value) {
                submitButton.value.disabled = true;
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            if(getTab == 'item') {
                if(user_data.value.idStore != 1) {
                    tempDataItem.tipe_produk = "RTW";
                }

                id = tempDataItem.id
                formData.append('id', tempDataItem.id)
                formData.append('brand_id', tempDataItem.brand_id)
                formData.append('sku', tempDataItem.sku)
                formData.append('artikel', tempDataItem.artikel)
                formData.append('raw_category_id', tempDataItem.raw_category_id)
                formData.append('tipe_produk', tempDataItem.tipe_produk)
                formData.append('vendor_id', tempDataItem.vendor_id)
                formData.append('price', tempDataItem.price)
                formData.append('color_id', tempDataItem.color_id)
                formData.append('size_id', tempDataItem.size_id)
                formData.append('image', tempDataItem.gambar ? imageItem.value.files[0] : null)
                formData.append('type', 'item')
            } else {
                id = tempDataDesain.id
                formData.append('id', parseInt(tempDataDesain.id))
                formData.append('sku', tempDataDesain.sku)
                formData.append('artikel', tempDataDesain.artikel)
                formData.append('name', tempDataDesain.name)
                formData.append('raw_category_id', 35) // Default Desain Kategori 35
                formData.append('size_category_id', tempDataDesain.size_category_id)
                formData.append('desain_kategori', tempDataDesain.desain_kategori)
                formData.append('print_size_id', tempDataDesain.print_size_id)
                formData.append('price', tempDataDesain.price)
                formData.append('vendor_id', 1) // Default Vendor 1
                formData.append('image', tempDataDesain.gambar)
                formData.append('type', 'desain')
            }

            let endpoint = type == 'add' ? 'create' : 'update/'+id

            try {
                await ApiService.post("product_raw/"+endpoint, formData)
                .then(({ data }) => {
                    if(data.error) {
                        throw Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    
                    if(type == 'add') 
                    {
                        tab.value == 'item'
                            ? totalRecordCountItem.value == 0 ? 0 : totalRecordCountItem.value
                            : totalRecordCountDesain.value == 0 ? 0 : totalRecordCountDesain.value

                        tab.value == 'item'
                            ? tableDataItem.forEach(o => o.index += 1)
                            : tableDataDesain.forEach(o => o.index += 1)

                        tab.value == 'item'
                            ? data.data.index = 0
                            : data.data.index = 0

                        tab.value == 'item' 
                            ? tableDataItem.unshift(data.data)
                            : tableDataDesain.unshift(data.data)

                        if(getTab == 'desain') {
                            modalVisible.value.desain = false
                            modalVisible.value.bank_desain = false
                        }
                    } 
                    else 
                    {
                        if(tab.value == 'item') {
                            tableDataItem.map((o, i) => { 
                                if(o.id == tempDataItem.id) { 
                                    Object.assign(o, data.data) 
                                } 
                            })
                        } else {
                            tableDataDesain.map((o, i) => { 
                                if(o.id == tempDataDesain.id) { 
                                    Object.assign(o, data.data) 
                                } 
                            })
                        }

                        modalVisible.value.item = false
                    }
                    
                    //modalVisible.value.item = false
                    //modalVisible.value.desain = false
                    //modalVisible.value.bank_desain = false
                })
                .catch(({ response }) => {
                    if(response.data.messages) { 
                        if(typeof response.data.messages === 'object')
                            throw Error(Object.entries(response.data.messages).map(([k, v]) => `${v}`).join(', \n'));
                        else 
                            throw Error(response.data.messages);
                    }
                });
            
            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            
            if(submitButton.value) {
                submitButton.value.removeAttribute("data-kt-indicator");
                submitButton.value.disabled = false;
            }
        }

        const removeObjectArray = (array, predicate) => {
            let end = 0;

            for (let i = 0; i < array.length; i++) {
                const obj = array[i];

                if (predicate(obj)) {
                    array[end++] = obj;
                }
            }

            array.length = end;
        };

        const onDelete = async(type, row) => {
            try {
                if(row.release) {
                    throw new Error("Maaf tidak bisa hapus produk raw. Produk jadi sudah release!");
                }
    
                await ApiService.post("product_raw/delete/"+row.id, null)
                .then(({ data }) => {
                    Swal.fire({
                        title: type == 'item' ? "Berhasil Menghapus Item" : "Berhasil Menghapus Desain",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });
                        
                })
                .catch(({ response }) => {
                    if(response) {
                        Swal.fire({
                            text: response.error,
                            icon: "error",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }

        const getBankdesain = async () => {
            await ApiService.setHeader();
            await ApiService.post("bankdesain?page=1", { filter: filter })
            .then(async ({ data }) => {
                await listBankDesain.splice(0)
                totalPage.value = data.total_page
                Object.assign(listBankDesain, data.list_data)
            })
            .catch(({ response }) => {
                console.log('Error getting list bankdesain');
            });
        }

        const getRawItem = async() => {
            try {
                await tableDataItem.splice(0)
                loadingTable.value = true

                let page = currentPage.value ? '?page=' + currentPage.value : '?page=1';
                let type = tab.value ? '&type=' + tab.value : '&type=item';
                let tipe_produk = filter.tipe_produk ? '&tipe_produk=' + filter.tipe_produk : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let sku = filter.sku ? '&sku=' + filter.sku : '';
                let artikel = filter.artikel ? '&artikel=' + filter.artikel : '';
                let category = filter.raw_category_id ? '&category=' + filter.raw_category_id : '';
                let vendor = filter.vendor_id ? '&vendor=' + filter.vendor_id : '';
                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("product_raw" + page + type + tipe_produk + brand + category + vendor + artikel + sku + search)
                .then(async ({ data }) => {
                    pagination.value = await data.data.pagination
                    Object.assign(tableDataItem, data.data.data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list item')
                });
            } catch(e) {
                console.log(e.message)
            }

            loadingTable.value = false
        }

        const getRawDesain = async() => {
            try {
                await tableDataDesain.splice(0)
                loadingTable.value = true

                let page = currentPage.value ? '?page=' + currentPage.value : '?page=1';
                let type = tab.value ? '&type=' + tab.value : '&type=desain';
                let desain_id = filter.desain_id ? '&desain_id=' + filter.desain_id : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';
                let jenis_desain = filter.jenis_desain ? '&jenis_desain=' + filter.jenis_desain : '';
                let search = filter.search ? '&search=' + filter.search : '';
                
                await ApiService.get("product_raw" + page + type + folder + jenis_desain + desain_id + search)
                .then( async ({ data }) => {
                    pagination.value = data.data.pagination
                    
                    Object.assign(tableDataDesain, data.data.data)
                })
                .catch(({ response }) => {
                    throw new Error('Error getting list desain')
                });

            } catch (e) {
                console.log(e.message)
            }

            loadingTable.value = false
        }

        const generate_kode = async(type) => {
            let data = {};

            type == 'artikel' 
                ? Object.assign(data, {raw_category_id: tempDataItem.raw_category_id, vendor_id: tempDataItem.vendor_id, color_id: tempDataItem.color_id,})
                : Object.assign(data, {artikel: tempDataItem.artikel, size_id: tempDataItem.size_id,})

            if(type == 'artikel' && (!tempDataItem.raw_category_id || !tempDataItem.vendor_id || !tempDataItem.color_id )) {
                return
            }

            if(type == 'sku' && (!tempDataItem.artikel || !tempDataItem.size_id)) {
                return
            }
                
            const loading = loadingFull({message: 'Generating kode...'})

            try {
                if(auto_kode.value) {
                    await ApiService.setHeader();
                    await ApiService.post("product_raw/generate_kode", data)
                    .then(({ data }) => {
                        if(data.error) {
                            throw Error(data.messages)
                        }
                        
                        type == 'artikel' 
                            ? tempDataItem.artikel = data.artikel
                            : tempDataItem.sku = data.sku
                    })
                    .catch((e) => {
                        throw Error(e.message)
                    });

                    // Generate sku
                    if(type == 'artikel' && (tempDataItem.sku || tempDataItem.size_id)) {
                        data = {
                            raw_category_id: tempDataItem.raw_category_id, 
                            vendor_id: tempDataItem.vendor_id,
                            color_id: tempDataItem.color_id, 
                            artikel: tempDataItem.artikel, 
                            size_id: tempDataItem.size_id,
                        }
                        
                        await ApiService.setHeader();
                        await ApiService.post("product_raw/generate_kode", data)
                        .then(({ data }) => {
                            if(data.error) {
                                throw Error(data.messages)
                            }
                            
                            tempDataItem.sku = data.sku
                        })
                        .catch((e) => {
                            throw Error(e.message)
                        });
                    }
                }

            } catch(e) {    
                Swal.fire({
                    title: "Error",
                    text: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            
            loading.close()
        }

        const stateRawProduct = computed(() => {
            let getRaw = store.getters.getRawProduct;
            return getRaw
        })

        const stateFilter = () => {
            try {
                const getCurrentRoutePath = route.path.split('/')[1]
                if(getCurrentRoutePath && router.options.history.state.back.includes(getCurrentRoutePath)) {
                  Object.assign(filter, store.getters.getFilter);
                }
            } catch(e) {
                console.error(e)
            }
        }

        onBeforeMount(async () => {
            setCurrentPageBreadcrumbs("Product Raw", ["Product Raw"]);
            
            stateFilter()

            if(filter.type) {
              tab.value = filter.type.toLowerCase()
            }

            if(tab.value == "item") {
                await getRawItem()
                getRawCategory()
                getListBrand();
                getVendor();
                img_url.value = process.env.VUE_APP_IMAGE_PRODUCTS
            } 
            
            if(tab.value == 'desain') {
                await getRawDesain()
                jenisDesain()
                folderDesain()
                img_url.value = process.env.VUE_APP_IMAGE_DESAINS
            }
        })

        watch(() => tab.value, (newVal, oldVal) => {
            filter.type = newVal
            store.dispatch(Actions.ADD_FILTER, filter);

            if(oldVal != newVal) {
                if(newVal == "item") {
                    getRawItem()
                    getRawCategory()
                    img_url.value = process.env.VUE_APP_IMAGE_PRODUCTS
                } else {
                    getRawDesain()
                    jenisDesain()
                    folderDesain()
                    img_url.value = process.env.VUE_APP_IMAGE_DESAINS
                }
            }
        })

        // Reset All values
        watch(() => modalVisible, (newVal) => {
            if(!newVal.bank_desain ) {
                search.value = null
            }
        }, { deep: true })

        return {
            tab, search, itemRef, filter, filterActive,
            user_data,
            img_url, img_url_mockup, img_url_desain, imageItem, imageDesain, onFileChange, changeImage, viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            tableHeaderItem, tableDataItem, totalRecordCountItem, 
            tableHeaderDesain, tableDataDesain, totalRecordCountDesain,
            tempDataItem, tempDataDesain, loadingTable, loadingButton, generate_kode, change_artikel, change_sku,
            modalVisible, modalClick, modalTitle, listRawCategory, listBrand, listColor, listSize, listVendor, listPrintSize, listDesainKategori, desainKategori, listFolderDesain, listJenisDesain,
            listBankDesain, handlePageDesainChange, handlePageChange, currentPage, onFilter, resetFilter, getDesign, totalPage, pagination,
            onSubmit, submitButton, onDelete,
            formatIDR,
        }
    }
})
</script>